import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { constructFormGroupByData, FormGroupDef } from '@core/utils';
import { UpdateEventArtistDto } from '@core/api/models/update-event-artist-dto';

export class EditEventArtistForm extends FormGroup<
  FormGroupDef<Partial<UpdateEventArtistDto>>
> {
  constructor(data: Partial<UpdateEventArtistDto>) {
    super(constructFormGroupByData(data));
  }

  addImageControl() {
    const imageControl = new FormControl(null);
    this.addControl('img', imageControl);
  }

  get linksArray() {
    // @ts-ignore
    return this.controls['links'] as FormArray;
  }

  addLink() {
    this.linksArray.push(new FormControl(''));
  }

  removeLink(index: number) {
    this.linksArray &&
      this.linksArray.length > 0 &&
      this.linksArray.controls.splice(index, 1);
  }
}
