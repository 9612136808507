import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { constructFormGroupByData, FormGroupDef } from '@core/utils';
import { UpdateEventInfoDto } from '@core/api/models/update-event-info-dto';

export class EventGeneralInfoForm extends FormGroup<
  FormGroupDef<Partial<UpdateEventInfoDto>>
> {
  constructor(data: UpdateEventInfoDto, private readonly injector: Injector) {
    super(constructFormGroupByData(data));
  }

  addMainImageControl() {
    const imageControl = new FormControl();
    this.addControl('main_img', imageControl);
  }

  addBackgroundImageControl() {
    const imageControl = new FormControl(null);
    this.addControl('background_img', imageControl);
  }
}
