import { DestroyRef, inject, signal, WritableSignal } from '@angular/core';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TuiAlertService } from '@taiga-ui/core';

export abstract class BaseAbstractPage {
  protected readonly location = inject(Location);
  protected readonly destroy$ = new Subject<void>();
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly notificationService = inject(TuiAlertService);
  protected readonly router = inject(Router);
  protected readonly route = inject(ActivatedRoute);
  public showLoader: WritableSignal<boolean> = signal(false);

  public back() {
    this.location.back();
  }
}
