<app-page-header
  [title]="'payment#list#title' | translate"
  [description]="'payment#list#description' | translate"
  [actionTitle]="'payment#list#add_btn' | translate"
  (action)="add()"
></app-page-header>

<tui-loader [overlay]='true' [showLoader]='isLoading()'>
  @if (paymentsSettings$ | async; as payments) {
    @if (payments?.data?.items.length !== 0) {
      <table
        tuiTable
        class='table'
        [columns]='displayedColumns'
        >
        <thead>
          <tr tuiThGroup>
            <th tuiTh>{{ 'payment#form#name' | translate }}</th>
            <th tuiTh>{{ 'payment#form#platform' | translate }}</th>
            <th tuiTh>{{ 'payment#form#active' | translate }}</th>
            <th tuiTh>{{ 'payment#table#dates' | translate }}</th>
            <th tuiTh>{{ 'common#table#actions' | translate }}</th>
          </tr>
        </thead>
        <tbody
          tuiTbody
          [data]='payments?.data?.items'
          >
          @for (item of payments?.data?.items; track item) {
            <tr
              tuiTr
              >
              <td
                *tuiCell="'name'"
                tuiTd
                >
                {{ item.name || '-' }}
              </td>
              <td
                *tuiCell="'platform'"
                tuiTd
                >
                {{ item.platform || '-' }}
              </td>
              <td
                *tuiCell="'active'"
                tuiTd
                >
                {{ item?.active || '-' }}
              </td>
              <td
                *tuiCell="'dates'"
                tuiTd
                >
                <span>{{ 'payment#table#update_date' | translate }}:</span>
                <span>{{ (item?.updated_at | date ) || '-' }}</span>
                <br>
                  <span>{{ 'payment#table#create_date' | translate }}:</span>
                  <span>{{ (item?.created_at | date ) || '-' }}</span>
                </td>
                <td
                  *tuiCell="'actions'"
                  tuiTd
                  >
                  <tui-icon class='icon-button' (click)='add(item)' icon="@tui.pencil"></tui-icon>
                </td>
              </tr>
            }
          </tbody>
          <tfoot>
            <tr>
              <td [colSpan]='displayedColumns.length'>
                <tui-pagination
                  [index]="currentPage - 1"
                  [length]="pages(payments?.data?.total)"
                  (indexChange)="onPage($event)"
                ></tui-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      }
    }
  </tui-loader>
