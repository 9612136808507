import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { EventEntity } from '@core/api/models/event-entity';
import { TuiIslandDirective } from '@taiga-ui/legacy';
import { ImageUrlPipe } from '@core/pipes/image-url.pipe';
import { TuiProgressBar, TuiProgressSegmented } from '@taiga-ui/kit';
import { DatePipe, NgStyle } from '@angular/common';

@Component({
  selector: 'event-card-component',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TuiIslandDirective,
    ImageUrlPipe,
    TuiProgressBar,
    TuiProgressSegmented,
    DatePipe,
    NgStyle
  ]
})
export class EventCardComponent {
  @Input() event: EventEntity;

  @Output() eventClick: EventEmitter<any> = new EventEmitter();
}
