<tui-loader [overlay]='true' [showLoader]='this.showLoader()'>
  @if (eventTicketTiers$ | async; as tickets) {
    <div>
      <header class='row'>
        <div class='col-xs-12'>
          <h2 class='tui-text_h2'>Квитки</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
        </div>
      </header>
      <div class='table-action-header'>
        <button
          tuiButton
          type='button'
          size='m'
          (click)='add()'
        >
          <tui-icon icon='@tui.plus'></tui-icon>
          Add new
        </button>
      </div>
      @if (tickets?.data.items.length !== 0) {
        <div tuiCardLarge tuiSurface="elevated">
          <table
          tuiTable
          class='table'
          [columns]='displayedColumns'
        >
          <thead>
          <tr tuiThGroup>
            <th
              tuiTh
              [resizable]='true'
            >
              Назва квитка
            </th>
            <th tuiTh>Вартість квитка</th>
            <th tuiTh>Кількість</th>
            <th tuiTh>Опис</th>
            <th tuiTh>Діючий</th>
            <th tuiTh></th>
          </tr>
          </thead>
          <tbody
            tuiTbody
            [data]='tickets.data.items'
          >
            @for (item of tickets.data.items; track item) {
              <tr
                tuiTr
              >
                <td
                  *tuiCell="'info'"
                  tuiTd
                >
                  <div class='img-with-title'>
                    <!--              <div class='avatar'><img [src]='item.img_url' [alt]='item.name' (error)='onImgError($event)'></div>-->
                    {{ item.name || '-' }}
                  </div>
                </td>
                <td
                  *tuiCell="'price'"
                  tuiTd
                >
                  {{ item.price || '-' }} {{ item.currency | tuiCurrency }}
                </td>
                <td
                  *tuiCell="'qty'"
                  tuiTd
                >
                  {{ item.total_quantity || '-' }}
                </td>
                <td
                  *tuiCell="'desc'"
                  tuiTd
                >
                  {{ item.description || '-' }}
                </td>
                <td
                  *tuiCell="'active'"
                  tuiTd
                >
                  {{ item.active || '-' }}
                </td>
                <td
                  class='text-right'
                  *tuiCell="'actions'"
                  tuiTd
                >
                  <tui-icon class='icon-button' (click)='add()' icon="@tui.pencil"></tui-icon>
                </td>
              </tr>
            }
          </tbody>
          <tfoot>
          </tfoot>
        </table>
        </div>
      }
      @if (tickets?.data.items.length === 0) {
        <app-empty-section-with-action
          [title]="'Ви ще не додавали квитки'"
          [description]="'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'"
          [iconName]="'@tui.users'"
          [actionTitle]="'Додати квиток'"
          (action)='add()'
        ></app-empty-section-with-action>
      }
    </div>
  }
</tui-loader>
