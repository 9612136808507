@if (iconName) {
  <div class='add-icon'>
    <tui-icon  [icon]='iconName'></tui-icon>
  </div>
}
@if (title) {
  <h2 class='tui-text_h2'>{{ title }}</h2>
}
@if (description) {
  <p>{{ description }}</p>
}
@if (actionTitle && action) {
  <button
    tuiButton
    type='button'
    appearance='secondary'
    class='tui-space_left-4'
    size='l'
    (click)='action.emit()'
    > {{ actionTitle }}
  </button>
}
