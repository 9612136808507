<tui-island class='event-card' (click)='eventClick.emit()'>
  <div class='img-holder' [ngStyle]="{'background-image': 'url(' + (event.general?.main_img | imageUrl) + ')'}">
    <span class='status'>{{ event.status }}</span>
  </div>
  <div class='content'>
    <div class='tickets'>
      <div class="row">
        <div class='col-xs-6'>
          <h3>{{ event.general?.title }}</h3>
        </div>
      </div>
      <div class='row between-xs'>
        <div class='col-xs-6'>Tickets</div>
        <div
          class='col-xs-6 tui-island_text-align_right'>{{ (event.tickets_amount - event.tickets_amount_vacant) || 0 }}
          / {{ event.tickets_amount || 0 }}
        </div>
      </div>
      <progress tuiProgressBar
        [max]='event.tickets_amount'  [segments]="event.tickets_amount"
        [value]='event.tickets_amount - event.tickets_amount_vacant'
      ></progress>
    </div>
  </div>
  <div class='hovered-content'>
    <div class='date'>{{ event.start_date_time | date }} - {{ event.end_date_time | date }}</div>
  </div>
</tui-island>
