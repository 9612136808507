import {
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { PaymentsService } from '@core/api/services';
import { PaymentSettingsEntity } from '@core/api/models/payment-settings-entity';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { TuiDialogService, TuiIcon, TuiLoader } from '@taiga-ui/core';
import { PaymentSettingsModal } from '../../modals/payment-settings/payment-settings.modal';
import { TranslateModule } from '@ngx-translate/core';
import { PageHeaderComponent } from '@shared/page-header/page-header.component';
import { AsyncPipe, DatePipe } from '@angular/common';
import {
  TuiTableCell,
  TuiTableDirective,
  TuiTableTbody,
  TuiTableTd,
  TuiTableTh,
  TuiTableThGroup,
  TuiTableTr
} from '@taiga-ui/addon-table';
import { TuiPagination } from '@taiga-ui/kit';

@Component({
  selector: 'payment-list-page',
  templateUrl: './payment-list.page.html',
  styleUrls: ['./payment-list.page.scss'],
  imports: [
    TranslateModule,
    PageHeaderComponent,
    TuiLoader,
    AsyncPipe,
    TuiTableDirective,
    TuiTableThGroup,
    TuiTableTh,
    TuiTableTbody,
    TuiTableTr,
    TuiTableTd,
    TuiTableCell,
    TuiIcon,
    TuiPagination,
    DatePipe
  ],
  standalone: true
})
export class PaymentListPage extends BaseAbstractPage implements OnInit {
  displayedColumns: string[] = [
    'name',
    'platform',
    'active',
    'dates',
    'actions'
  ];
  private dialogs = inject(TuiDialogService);
  private paymentsService = inject(PaymentsService);
  isLoading: WritableSignal<boolean> = signal(true);
  pageSize = 10;
  currentPage = 1;

  public paymentsSettings$: Observable<{
    state?: boolean;
    data?: {
      total?: number;
      limit?: number;
      page?: number;
      items?: Array<PaymentSettingsEntity>;
    };
    message?: string;
  }>;

  public pages(total: number) {
    return Math.ceil(total / this.pageSize);
  }

  async ngOnInit() {
    this.fetchData();
    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  fetchData() {
    this.isLoading.set(true);
    this.paymentsSettings$ = this.paymentsService
      .paymentControllerFindAllByCompanyId({
        limit: 50,
        page: 1
      })
      .pipe(finalize(() => this.isLoading.set(false)));
  }

  onSize(size: number): void {
    this.pageSize = size;
    this.fetchData();
  }

  onPage(page: number): void {
    this.currentPage = page;
    this.fetchData();
  }

  add(payment?: PaymentSettingsEntity): void {
    this.dialogs
      .open<boolean>(new PolymorpheusComponent(PaymentSettingsModal), {
        data: {
          payment: payment || null
        },
        dismissible: true,
        appearance: 'custom-modal'
      })
      .subscribe({
        next: (data) => {
          if (data) {
            this.fetchData();
          }
          console.info(`Dialog emitted data = ${data}`);
        }
      });
  }
}
