import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { EventProgramEntity } from '@core/api/models/event-program-entity';
import { TuiButton, TuiDialogService, TuiIcon, TuiLoader, TuiSurface } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { EventProgramModal } from '../../modals/event-program/event-program.modal';
import { onImageError } from '@core/utils';
import { finalize } from 'rxjs/operators';
import { EventService } from '@core/api/services';
import { AsyncPipe } from '@angular/common';
import {
  TuiTableCell,
  TuiTableDirective,
  TuiTableTbody,
  TuiTableTd,
  TuiTableTh,
  TuiTableThGroup,
  TuiTableTr
} from '@taiga-ui/addon-table';
import { ImageUrlPipe } from '@core/pipes/image-url.pipe';
import { EmptySectionWithActionComponent } from '@shared/empty-section-with-action/empty-section-with-action.component';
import { TuiAvatar } from '@taiga-ui/kit';
import { TuiCardLarge } from '@taiga-ui/layout';
import { BaseAbstractComponent } from '../../../base.abstract.component';

@Component({
  selector: 'event-program-list-component',
  templateUrl: './event-program-list.component.html',
  styleUrls: ['./event-program-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TuiLoader,
    AsyncPipe,
    TuiButton,
    TuiTableDirective,
    TuiTableThGroup,
    TuiTableTh,
    TuiTableTbody,
    TuiTableTr,
    TuiTableCell,
    TuiTableTd,
    ImageUrlPipe,
    EmptySectionWithActionComponent,
    TuiIcon,
    TuiAvatar,
    TuiCardLarge,
    TuiSurface
  ]
})
export class EventProgramListComponent extends BaseAbstractComponent implements OnInit {
  @Input() eventId!: string;
  private eventsService = inject(EventService);
  private dialogs = inject(TuiDialogService);
  public eventProgram$: Observable<{
    state?: boolean;
    data?: {
      total?: number;
      limit?: number;
      page?: number;
      items?: Array<EventProgramEntity>;
    };
    message?: string;
  }>;
  public readonly displayedColumns: string[] = [
    'img_url',
    'title',
    'short_description',
    'time',
    'actions'
  ];

  ngOnInit(): void {
    this.loadData();

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  loadData() {
    this.showLoader.set(true);
    this.eventProgram$ = this.eventsService
      .eventControllerFindAllProgramsByEventId({
        event_id: this.eventId,
        limit: 50,
        page: 1
      })
      .pipe(finalize(() => this.showLoader.set(false)));
  }

  add(program?: EventProgramEntity) {
    this.dialogs
      .open<boolean>(new PolymorpheusComponent(EventProgramModal), {
        data: {
          event_id: this.eventId,
          program: program || null
        },
        dismissible: true,
        appearance: 'custom-modal'
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          if (data) {
            this.loadData();
          }
          console.info(`Dialog emitted data = ${data}`);
        }
      });
  }

  protected readonly onImgError = onImageError;
}
