@if (eventInfo$ | async; as eventInfo) {
  <div>
    <header class='row'>
      <div class='col-xs-12'>
        <h2 class='tui-text_h2'>Основний блок</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
      </div>
    </header>
    <div tuiCardLarge tuiSurface="elevated">
      @if (form) {
        <div class='row' [formGroup]='form'>
          <div class='col-xs-12 col-md-7'>
            <div class='row'>
              <div class='col-md-6'>
                <p>Main image</p>
                <app-image-picker
                  [existImageUrl]="eventInfo.main_img"
                  (selectImage)="pickMainImageImage($event)"
                ></app-image-picker>
              </div>
              <div class='col-md-6'>
                <p>Background image</p>
                <app-image-picker
                  [existImageUrl]="eventInfo.background_img"
                  (selectImage)="pickBackgroundImageImage($event)"
                ></app-image-picker>
              </div>
            </div>
          </div>
          <div class='col-md-5'>
            <tui-input
              class='tui-space_bottom-4'
              formControlName='title'>
              Type an name
            </tui-input>
            <tui-textarea
              formControlName='description'
              class='tui-space_bottom-4'
              [expandable]='true'
            >
              Type a text
            </tui-textarea>
          </div>
        </div>
      }
      <hr>
      <div class='form-footer'>
        <button
          tuiButton
          type='button'
          (click)='onSubmit()'
          [disabled]='form.invalid'
        >
          Save
        </button>
      </div>
    </div>
  </div>
}
