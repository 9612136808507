<tui-loader [overlay]='true' [showLoader]='this.showLoader()'>
  @if (eventProgram$ | async; as program) {
    <div>
      <header class='row'>
        <div class='col-xs-12'>
          <h2 class='tui-text_h2'>Блок програма</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
        </div>
      </header>
      <div class='table-action-header'>
        <button
          tuiButton
          type='button'
          size='m'
          (click)='add()'
        >
          <tui-icon icon='@tui.plus'></tui-icon>
          Add new
        </button>
      </div>
      @if (program?.data.items.length !== 0) {
        <div tuiCardLarge tuiSurface="elevated">
          <table
            tuiTable
            class='table'
            [columns]='displayedColumns'
          >
            <thead>
            <tr tuiThGroup>
              <th
                tuiTh
                [resizable]='true'
              >
                Картинка та назва пункту
              </th>
              <th tuiTh>Назва</th>
              <th tuiTh>Опис</th>
              <th tuiTh>Час</th>
              <th tuiTh></th>
            </tr>
            </thead>
            <tbody
              tuiTbody
              [data]='program?.data.items'
            >
              @for (item of program?.data.items; track item) {
                <tr
                  tuiTr
                >
                  <td
                    *tuiCell="'img_url'"
                    tuiTd
                  >
                    <tui-avatar size="s" src="{{item.img_url | imageUrl}}"></tui-avatar>
                  </td>
                  <td
                    *tuiCell="'title'"
                    tuiTd
                  >
                    {{ item.title || '-' }}
                  </td>
                  <td
                    *tuiCell="'short_description'"
                    tuiTd
                  >
                    {{ item.short_description || '-' }}
                  </td>
                  <td
                    *tuiCell="'time'"
                    tuiTd
                  >
                    {{ item.time || '-' }}
                  </td>
                  <td
                    class='text-right'
                    *tuiCell="'actions'"
                    tuiTd
                  >
                    <tui-icon class='icon-button' (click)='add(item)' icon="@tui.pencil"></tui-icon>
                  </td>
                </tr>
              }
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>
      }
      @if (program?.data.items.length === 0) {
        <app-empty-section-with-action
          [title]="'Ви ще не додавали учасників'"
          [description]="'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'"
          [iconName]="'@tui.users'"
          [actionTitle]="'Додати учасника івенту'"
          (action)='add()'
        ></app-empty-section-with-action>
      }
    </div>
  }
</tui-loader>
